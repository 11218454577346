import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteAreaApiService } from '../../api/site-area/site-area.api.service';
import { SiteArea, SubSiteAreaAction } from '../../types/SiteArea';
import { ActionResponse } from '../../types/DataResult';
import { RestResponse } from '../../types/GlobalType';

@Injectable()
export class SiteAreaRepositoryService {
  public constructor(private readonly siteAreaApi: SiteAreaApiService) {}

  public getSiteArea(
    siteAreaId: string,
    options: { withSite?: boolean; withParentSiteArea?: boolean },
  ): Observable<SiteArea> {
    return this.siteAreaApi.getSiteArea(siteAreaId, options).pipe(
      map((siteArea) => {
        // Transform Amps to Watt
        if (siteArea.smartChargingParameters?.defaultCurrent) {
          siteArea.smartChargingParameters!.defaultCurrent = this.ampsToWatt(
            siteArea.smartChargingParameters?.defaultCurrent,
            siteArea.voltage,
            siteArea.numberOfPhases,
          );
        }

        // Transform Amps to Watt
        if (siteArea.smartChargingParameters?.minimalCurrent) {
          siteArea.smartChargingParameters.minimalCurrent = this.ampsToWatt(
            siteArea.smartChargingParameters?.minimalCurrent,
            siteArea.voltage,
            siteArea.numberOfPhases,
          );
        }

        if (siteArea.smartChargingParameters?.safetyMargin) {
          siteArea.smartChargingParameters.safetyMargin = siteArea.smartChargingParameters.safetyMargin * 100;
        }

        return siteArea;
      }),
    );
  }

  public updateSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.updateSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be updated');
        }

        return response;
      }),
    );
  }

  public createSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.createSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be created');
        }

        return response;
      }),
    );
  }

  private updateSiteAreaSmartChargingInformation(siteArea: SiteArea) {
    const updatedSiteArea = { ...siteArea };

    // Convert watt to amps
    if (updatedSiteArea.smartChargingParameters?.defaultCurrent) {
      updatedSiteArea.smartChargingParameters.defaultCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.defaultCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    // Convert watt to amps
    if (updatedSiteArea.smartChargingParameters?.minimalCurrent) {
      updatedSiteArea.smartChargingParameters.minimalCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.minimalCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    if (updatedSiteArea.smartChargingParameters?.safetyMargin) {
      updatedSiteArea.smartChargingParameters.safetyMargin = updatedSiteArea.smartChargingParameters.safetyMargin / 100;
    }

    return updatedSiteArea;
  }

  private ampsToWatt(amps: number, voltage: number, numberOfPhases: number): number {
    if (amps === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return amps * voltage * numberOfPhases;
  }

  private wattToAmps(watt: number, voltage: number, numberOfPhases: number): number {
    if (watt === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return watt / voltage / numberOfPhases;
  }
}
