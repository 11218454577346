import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildUrl } from '../../utils/url/url.util';
import { RESTServerRoute } from '../../types/Server';
import { SiteArea, SubSiteAreaAction } from '../../types/SiteArea';
import { ActionResponse } from '../../types/DataResult';

@Injectable()
export class SiteAreaApiService {
  public constructor(private httpClient: HttpClient) {}

  public getSiteArea(
    siteAreaId: string,
    { withSite, withParentSiteArea }: { withSite?: boolean; withParentSiteArea?: boolean } = {},
  ): Observable<SiteArea> {
    const params: { [param: string]: string } = {};
    if (withSite) {
      params['WithSite'] = withSite.toString();
    }
    if (withParentSiteArea) {
      params['WithParentSiteArea'] = withParentSiteArea.toString();
    }

    const url = buildUrl(RESTServerRoute.REST_SITE_AREA, { id: siteAreaId });
    return this.httpClient.get<SiteArea>(url, { params });
  }

  public updateSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_AREA, { id: siteArea.id });
    return this.httpClient.put<ActionResponse>(url, {
      ...siteArea,
      subSiteAreasAction: subSiteAreaActions.join('|'),
    });
  }

  public createSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_AREA, { id: siteArea.id });
    return this.httpClient.post<ActionResponse>(url, {
      ...siteArea,
      subSiteAreasAction: subSiteAreaActions.join('|'),
    });
  }
}
